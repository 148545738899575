<style scoped lang="less">
  .container {
    display: flex;
    transition: all .3s;
    height: 100%;
    &.full {
      position: fixed;
      background-color: #F8F8F8;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9;
    }
    &.hide-cert .left {
      background-color: transparent;
      width: 35px;
      .view-action {
        flex-direction: column;
      }
    }
  }
  .left {
    transition: all .3s;
    background-color: #FFF;
    margin: 10px;
    border-radius: 5px;
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  .types {
    flex: 1;
    height: 0;
    overflow: auto;
  }
  .right {
    width: 0; flex: 1;
    border-radius: 5px;
    overflow: hidden;
  }
  .type-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
    font-size: 15px;
    .loading {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .type {
    border-radius: 5px;
    border: 1px solid #EEE;
    margin: 0 10px;
    padding: 10px;
    box-sizing: border-box;
    transition: all .3s;
    &.checked {
      background-color: #EEE;
      .type-name {
        color: #000;
      }
    }
    & + .type {
      margin-top: 10px;
    }
  }
  .certs {
    
  }
  .cert {
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    background-color: #F8F8F8;
    text-align: center;
    transition: all .3s;
    padding: 5px 0;
    margin-top: 10px;
    position: relative;
    &.empty {
      color: #999 !important;
      cursor: default !important;
    }
  }
  .view-action {
    padding: 5px;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    .text-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .text-btn {
    transition: all .3s;
  }
  .render-action {
    border-top: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    .text-btn {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      text-align: center;
      line-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      & + .text-btn {
        border-left: 1px solid #eee;
      }
    }
  }
  .pink-theme {
    .text-btn {
      &:hover, &:active {
        color:  #F4628F;
      }
    }
    .cert:hover {
      color: #F4628F;
    }
    .cert.checked {
      color: #FFF;
      background-color: #F4628F;
    }
  }
  .blue-theme {
    .text-btn {
      &:hover, &:active {
        color:  #005AFF;
      }
    }
    .cert:hover {
      color: #005AFF;
    }
    .cert.checked {
      color: #FFF;
      background-color: #005AFF;
    }
  }
  .dark-theme, .withe-theme {
    .text-btn {
      &:hover, &:active {
        color:  #1cb5e0;
      }
    }
    .cert:hover {
      color: #1cb5e0;
    }
    .cert.checked {
      color: #FFF;
      background-color: #1cb5e0;
    }
  }
  .yellow-theme {
    .text-btn {
      &:hover, &:active {
        color:  #6C573C;
      }
    }
    .cert:hover {
      color: #6C573C;
    }
    .cert.checked {
      color: #FFF;
      background-color: #6C573C;
    }
  }
</style>

<template>
  <div>
    <div class="container" :class="{
      'hide-cert': !status.cert,
      'full': status.full
    }">
      <div class="left">
        <div class="view-action">
          <template v-if="status.cert">
            <span v-if="status.full === false" class="text-btn" @click="status.full = true">
              <Icon style="cursor: pointer;" size="20" type="md-expand"/> 开启全屏
            </span>
            <span v-else class="text-btn" @click="status.full = false">
              <Icon style="cursor: pointer;" size="20" type="md-contract"/> 退出全屏
            </span>
            <span v-if="status.cert" class="text-btn" @click="status.cert = false">
              <Icon style="cursor: pointer;" size="20" type="ios-contract" /> 收起证书列表
            </span>
          </template>
          <template v-else>
            <Tooltip placement="right" content="退出全屏" v-if="status.full">
              <Icon style="cursor: pointer;" size="25" type="md-contract" @click="status.full = false"/>
            </Tooltip>
            <Tooltip placement="right" content="开启全屏" v-else>
              <Icon style="cursor: pointer;" size="25" type="md-expand" @click="status.full = true"/>
            </Tooltip>
            <Tooltip placement="right" content="展开证书列表" v-if="status.cert === false">
              <Icon style="cursor: pointer;" size="25" type="ios-expand" @click="status.cert = true"/>
            </Tooltip>
          </template>
        </div>
        <fm-title title-text="快速打印" :note-text="certNum > 0 && imgNum > 0 ? ('已选' + certNum + '项，共' + imgNum + '张图') : ''" v-if="status.cert" />
        <div class="scrollbar types" v-if="status.cert">
          <div v-for="(item, i) in certs" :key="i" class="type" :class="{checked: item.checked}">
            <div class="type-name" @click="checkType(item)">
              {{item.label}}
              <span class="loading" v-if="item.loading">加载中...</span>
            </div>
            <div class="certs">
              <template v-for="(cert, l) in item.dataList">
                <div :key="l" v-if="cert.sources.length" class="cert" :class="{checked: item.checkedList.includes(cert.id)}" @click.stop="checkCert(item, cert)">
                  {{cert.sources[0].label}}
                </div>
                <div :key="l" class="cert empty" v-else>{{cert.sourceName || '/'}} - 无扫描件</div>
              </template>
            </div>
          </div>
        </div>
        <div class="render-action" v-if="status.cert">
          <div class="text-btn" @click="checkAll"><Icon style="margin-right: 5px;" size="20" type="md-checkbox" />全选</div>
          <div class="text-btn" @click="reverseCheck"><Icon style="margin-right: 5px;" size="20" type="md-checkbox-outline" />反选</div>
          <div class="text-btn" @click="render"><Icon style="margin-right: 5px;" size="20" type="logo-buffer" />生成</div>
        </div>
      </div>
      <print :key="loadTag" class="right" @quit="$router.back()" :sources="sources" />
    </div>
  </div>
</template>

<script>
import Print from '@/components/base/file/print'
import { certs, getWorkerInfo } from './libs'

export default {
  name: 'ResumePrint',
  components: { Print },
  data () {
    return {
      certs: [],
      sources: [],
      status: {
        printer: false,
        print: false,
        full: false,
        cert: true
      },
      loading: {
        printer: false,
        load: false
      }
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    loadTag () {
      return this.status.full + '-' + this.status.cert + '-' + new Date().getTime()
    },
    certNum () {
      return this.certs.filter(v => v.checked).length
    },
    imgNum () {
      return this.certs.filter(v => v.checked).reduce((a, b) => a + b.checkedList.length, 0)
    }
  },
  watch: {
    id: {
      handler () {
        this.loadData()
      }
    }
  },
  methods: {
    checkAll () {
      this.certs.map(item => {
        item.checkedList = item.dataList.filter(v => v.sources.length).map(v => v.id)
        item.checked = item.checkedList.length > 0
      })
    },
    reverseCheck () {
      this.certs.map(item => {
        item.checkedList = item.dataList.filter(v => v.sources.length).map(v => {
          return item.checkedList.includes(v.id) ? false : v.id
        }).filter(v => v)
        item.checked = item.checkedList.length > 0
      })
    },
    render () {
      this.sources = this.certs.filter(v => v.checked && v.dataList.length).map(item => {
        if (item.name === 'idcard') { // 身份证特殊处理
          return {
            justifyContent: 'space-evenly',
            sources: item.dataList.filter(v => {
              return item.checkedList.includes(v.id)
            }).map(v => {
              return v.sources.filter(v => v || (Array.isArray(v) && v.length))
            }).flat().map(v => {
              return v.src
            }).flat().filter(v => v).map(v => {
              return { src: v, height: '6.4cm' }
            })
          }
        } else if (['edu', 'other', 'rew'].includes(item.name)) { // 一页一张
          return item.dataList.filter(v => {
            return item.checkedList.includes(v.id)
          }).map(cert => {
            return cert.sources.filter(v => {
              return v || (Array.isArray(v) && v.length)
            }).map(v => v.src).flat().filter(v => v)
          }).flat().map(v => {
            return {
              justifyContent: 'space-evenly',
              sources: [
                { src: v, rotate: -90 }
              ]
            }
          })
        } else { // 一页两张
          const pages = []
          item.dataList.filter(v => {
            return item.checkedList.includes(v.id)
          }).forEach(cert => {
            const sources = cert.sources.filter(v => {
              return v || (Array.isArray(v) && v.length)
            }).map(v => v.src).flat().filter(v => v).map(v => {
              return { src: v }
            })
            for (let i = 0; i < sources.length; i += 2) {
              pages.push({
                justifyContent: 'space-evenly',
                sources: sources.slice(i, i + 2)
              })
            }
          })
          
          return pages
        }
      }).flat()
      console.log(this.sources)
    },
    checkType (item) {
      item.checked = item.dataList.length ? !item.checked : false
      item.checkedList = item.checked ? item.dataList.filter(v => v.sources.length).map(v => v.id) : []
      if (item.checked && !item.checkedList.length) {
        item.checked = false
      }
      if (!item.checked) {
        item.checkedList = []
      }
    },
    checkCert (item, cert) {
      if (item.checkedList.includes(cert.id)) {
        const index = item.checkedList.findIndex(v => v === cert.id)
        item.checkedList.splice(index, 1)
      } else {
        item.checkedList.push(cert.id)
      }
      item.checked = item.checkedList.length > 0
    },
    async loadData () {
      if (this.$route.name.indexOf('hrms.resume.print') !== 0) {
        return
      }
      if (!this.id) {
        this.$Message.error('参数缺失')
        this.$router.back()
        return
      }

      const has = await getWorkerInfo(this.id)
      if (!has) {
        this.$Message.error('用户不存在')
        this.$router.back()
        return
      }

      this.loading.load = true
      let loadList = [
        { label: '身份证', name: 'idcard' },
        { label: '学历证', name: 'edu' },
        { label: '事业单位岗位聘任证书', name: 'hire' },
        { label: '执业证书', name: 'pra' },
        { label: '专业技术资格证书', name: 'qua' },
        { label: '职业资格证书', name: 'doc_qua' },
        { label: '其他证书', name: 'other' },
        { label: '奖励情况', name: 'rew' },
        { label: '培训信息', name: 'train' },
      ].map(v => {
        return {
          loading: false,
          label: v.label,
          name: v.name,
          dataList: null,
          checked: false,
          checkedList: []
        }
      })

      const promise = loadList.map(async item => {
        item.loading = true
        item.dataList = await certs.find(v => v.name === item.name).getData(this.id)
        item.loading = false
      })

      this.certs = loadList
      
      this.$Loading.start()
      await Promise.all(promise)
      this.$Loading.finish()

      console.log(this.certs)
      // this.checkAll()
      // this.render()
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  },
  deactivated () {
    this.$destroy()
  }
}
</script>
